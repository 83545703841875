import Card from "./Card";
function Services() {
  return (
    <div className="container services">
        <h2 className="main-title text-center">SERVICES</h2>
        <div className="card-cover">
            <div className="col-md-12">
                <div className="row">
                    <div className="col-md-4 mb-2">
                        <Card title="Cloud Servers" img="card1.png" text="Reliability, scalability, we know what you want" />
                    </div>
                    <div className="col-md-4 mb-2">
                    <Card title="Best Price" img="card2.png" text="Affordable even though you are just starting" />
                    </div>
                    <div className="col-md-4 mb-2">
                    <Card title="Blockchain Security" img="card3.png" text="State-of-the-art blockchain infrastructure with best performance and security." />
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
}
export default Services;
