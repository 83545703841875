import About from '../components/About';
import Services from '../components/Services';
// import Contact from '../components/Contact';
import Card from '../components/Card';

function Main() {
  return (
    <>
      <main>
        <Services />
        <About />
        {/* <Contact /> */}
        {/* Employees */}
        <div className="container services">
          <h2 className="main-title text-center">Team</h2>
          <div className="card-cover">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-4 mb-2">
                  <Card title="CEO" img="e1.jfif" text="David Kidd" />
                </div>
                <div className="col-md-4 mb-2">
                  <Card title="CTO" img="e2.jfif" text="Sean Shi" />
                </div>
                <div className="col-md-4 mb-2">
                  <Card title="CFO" img="e3.jfif" text="Jane Randolf Lee" />
                </div>
              </div>
              <div className="row">
                <div className="col-md-4 mb-2">
                  <Card title="Senior Software Engineer" img="e6.jfif" text="Sam Harris" />
                </div>
                <div className="col-md-4 mb-2">
                  <Card title="Software Engineer" img="e4.jfif" text="Chauncey Sue Dodge" />
                </div>
                <div className="col-md-4 mb-2">
                  <Card title="Site Reliability Engineer" img="e5.jfif" text="Marianne Navarro" />
                </div>
              </div>
            </div>
          </div>
        </div>

      </main>
    </>
  );
}
export default Main;
