function About() {
  return (
    <>
      <div style={{marginTop:'15rem',width:'100%',height:'10px'}} className="about-scroll"></div>

      <div className="container about">
        <div className="row">
          <div className="col-md-6 text-center">
            <img alt="about" src="../img/img1.png" className="img-fluid" />
          </div>
          <div className="col-md-6">
            <h2 className="main-title about-h2">ABOUT</h2>
            <p className="main-p">
              We are the leading blockchain application infrastructure provider. We
              provide the best blockchain infrastructure for your business.
              With the state-of-the-art blockchain RPC services, we are the best
              choice for your business.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
export default About;
